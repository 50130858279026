import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import CategoryStatement from '../components/Statement/categoryStatement'
import { graphql, Link } from 'gatsby';
import Section from '../components/Section/section'
import TagSection from '../components/Section/tagSection/tagSection'
import CardList from '../components/article/articleList/articleList'



const Written = ({ data }) => {
  console.log("data ", data)
  const tags = data.tagsGroup.group
  const url = typeof window !== 'undefined' ? window.location.href : '';
  const posts = data.allMarkdownRemark.edges
  console.log("url", url)
  const postsPerPage = 9
  let featured;
  const postsWithoutFeatured = posts.filter(({ node }) => {
    if (node.frontmatter.featured) {
      featured = node
    }
    return !node.frontmatter.featured
  })

  const numPages = Math.ceil(postsWithoutFeatured.length / postsPerPage)
  const subheader = "Bold ideas, \n Knowledge bombs \n and need to knows in the crypto space."

  const SEO = {
    pageInfo: "Ntheos Mag | Written Content"
  }

  return (
    <Layout seo={SEO}>
      <CategoryStatement category="written" subheader={subheader} />
      <Section section='tags' header bg='white' heading='' size='auto'>
        <TagSection type="written" tags={tags} />
      </Section>

      <Section bg='white' section="latest" size='auto'>
        <CardList posts={postsWithoutFeatured} />
      </Section>

    </Layout>
  )
};

Written.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Written;

export const query = graphql`
 query WrittenPageQuery {
  tagsGroup: allMarkdownRemark(
    filter: {frontmatter: {type: {eq: "mag"}}}
  ) {
    group(field: frontmatter___tags) {
      totalCount
      fieldValue
    }
  }
  allMarkdownRemark(
    sort: {order: DESC, fields: frontmatter___date}
    filter: {frontmatter: {type: {eq: "mag"}}}) {
    edges {
      node {
        fields {
          slug
        }
        frontmatter {
          title
          description
          date(formatString: "ddd MMMM Do, YYYY")
          tags
          opener
          featured
          cover {
            childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
          }
        }
      }
    }
  }
}
`;